import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Chart from 'chart.js/auto';
import Slider from 'react-slick';
import AddToRadar from './AddToRadar';
import Header from './Header';
import './ProductPage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { capitalizeFirstLetterOfEachWord, formatPrice } from '../utils';
import ProductBox from './ProductBox';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import BackButton from './BackButton';

const ProductPage = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [priceComparizons, setPriceComparizons] = useState([]);
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

        // Your Amazon affiliate tracking ID
        const affiliateId = 'glamalertz-21'; // Replace with your actual tracking ID

        // Function to generate the affiliate link in Amazon's simplified format
const getAffiliateLink = (link, store) => {
    const url = new URL(link);
    
    // Only apply affiliate tag if it's an Amazon product
    if (store === 'amazon') {
        // Extract the ASIN from the Amazon URL
        const asinMatch = url.pathname.match(/\/dp\/([A-Z0-9]{10})/);
        
        if (asinMatch && asinMatch[1]) {
            const asin = asinMatch[1];
            
            // Create the simplified link
            const simplifiedLink = `https://www.amazon.sa/dp/${asin}/ref=nosim?tag=${affiliateId}`;
            
            return simplifiedLink;
        }
    }
    
    // Return the original link if not Amazon or if ASIN extraction fails
    return link;
};

// // Function to generate the affiliate link
// const getAffiliateLink = (link, store) => {
//     // Log the original link
//     console.log('Original Link:', link);
    
//     const url = new URL(link);
    
//     // Only append the tracking ID if the product is from Amazon
//     if (store === 'amazon') {
//         console.log('Appending affiliate tag...');
//         url.searchParams.append('tag', affiliateId); // Appending the tracking ID
//     } else {
//         console.log('Store is not Amazon, skipping tag append.');
//     }

//     // Log the generated link
//     console.log('Generated Link:', url.toString());
//     return url.toString();
// };



    const fetchProductDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/main/product/${id}`);
            setProduct(response.data);
            // console.log(product, "product") 
        } catch (error) {
            console.error("Error fetching product details:", error);
        }
    };

    const fetchPriceComparizon = async (searchTerm) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/main/price-compare`, {
            params: { searchTerm }
          });
          // Extract the array of products from the "stores" property
          const fetchedProducts = response.data.stores;
          
          // Use a Set to track unique product IDs (if needed)
          const uniqueProducts = [];
          const productIds = new Set();
      
          for (const product of fetchedProducts) {
            if (!productIds.has(product._id)) {
              uniqueProducts.push(product);
              productIds.add(product._id);
            }
          }
      
          setPriceComparizons(uniqueProducts);
          console.log(uniqueProducts, "price Comparizons!");
        } catch (error) {
          console.error("Error fetching similar products:", error);
        }
      };
      

    const fetchSimilarProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/main/similar/${id}`);
            const fetchedProducts = response.data;
    
            // Use a Set to track unique product IDs
            const uniqueProducts = [];
            const productIds = new Set();
    
            for (const product of fetchedProducts) {
                if (!productIds.has(product._id)) {
                    uniqueProducts.push(product);
                    productIds.add(product._id);
                }
            }
    
            setSimilarProducts(uniqueProducts);
            // console.log(similarProducts, "similar products")
        } catch (error) {
            console.error("Error fetching similar products:", error);
        }
    };

    const fetchPriceHistory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/product/history/${id}`);
            const priceHistory = response.data.map(item => ({
                ...item,
                price: parseFloat(item.price.match(/\d+(\.\d+)?/)[0])
                // price: item.price
            }));

            // console.log(priceHistory, "price history")

            const labels = priceHistory.map(entry => new Date(entry.timestamp).toLocaleDateString());
            const data = priceHistory.map(entry => entry.price);
    
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
    
            if (chartRef.current) {
                const ctx = chartRef.current.getContext('2d');
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [{
                            label: 'Price History',
                            data,
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 2,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: 'Date'
                                }
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: 'Price (SAR)'
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: true
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error fetching price history:", error);
        }
    };
    

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        fetchProductDetails();
        fetchSimilarProducts();
    }, [id]);

    useEffect(() => {
        if (product) {
            fetchPriceComparizon(product.name);
            fetchPriceHistory();
            scrollToTop();
        }
    }, [product]);

    useEffect(() => {
        if (product) {
          const link = getAffiliateLink(product.link, product.store);
          // Open a small, almost invisible popup
          const newTab = window.open(link, '_blank', 'width=1,height=1,left=-1000,top=-1000');
      
          const timer = setTimeout(() => {
            if (newTab) {
              newTab.close();
            }
          }, 2000); // Close after 1 second
      
          return () => {
            clearTimeout(timer);
            if (newTab && !newTab.closed) {
              newTab.close();
            }
          };
        }
      }, [product]);
      

    //   useEffect(() => {
    //     if (product) {
    //       // Open the new tab in the background without focusing on it
    //       const link = getAffiliateLink(product.link, product.store);
    //       const newTab = window.open(link, '_blank', 'noopener,noreferrer');
      
    //       if (newTab) {
    //         // Close the tab after a delay (e.g., 1 second)
    //         const timer = setTimeout(() => {
    //           newTab.close();
    //         }, 2000);
      
    //         // Cleanup the timer on component unmount
    //         return () => {
    //           clearTimeout(timer);
    //           if (!newTab.closed) {
    //             newTab.close(); // Ensure the tab is closed on cleanup
    //           }
    //         };
    //       }
    //     }
    //   }, [product]);
      

    // useEffect(() => {
    //     if (product) {
    //         const link = getAffiliateLink(product.link, product.store);
            
    //         // Create an invisible iframe and append it to the body
    //         const iframe = document.createElement('iframe');
    //         iframe.style.display = 'none';
    //         iframe.src = link;
            
    //         // Append the iframe to the body to load the page in the background
    //         document.body.appendChild(iframe);
            
    //         // Optionally, you can remove the iframe after a certain time
    //         const timer = setTimeout(() => {
    //             document.body.removeChild(iframe);
    //         }, 5000); // Remove after 5 seconds (or whatever time you prefer)
        
    //         // Cleanup the timer on component unmount
    //         return () => clearTimeout(timer);
    //     }
    // }, [product]);  // This effect runs when `product` is available
    
   
    // useEffect(() => {
    //     if (product) {
    //         const link = getAffiliateLink(product.link, product.store);
    
    //         // Open the link in a new tab
    //         const newTab = window.open(link, '_blank', 'noopener,noreferrer');
    
    //         if (!newTab) {
    //             console.error('The new tab was blocked by the browser.');
    //         } else {
    //             // Keep the focus on the current tab
    //             newTab.blur();
    //             window.focus();
    //         }
    //     }
    // }, [product]);
    

    const slidesToShowLarge = (similarProducts.length < 3)? similarProducts.length : 3;
    const slidesToShowMedium = (similarProducts.length < 2)? similarProducts.length : 2;
    // const slidesToShowSmall = (similarProducts.length < 1)? similarProducts.length : 1;
    const settings = {
        dots: true,
        // infinite: true,
        infinite: false,
        speed: 500,
        // slidesToShow: 3,
        slidesToShow: slidesToShowLarge,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    // slidesToShow: 2,
                    slidesToShow: slidesToShowMedium,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="product-page-container">
            {product ? (
                <>
                    <Helmet>
                        <title>{`${capitalizeFirstLetterOfEachWord(product.name)} | GlamAlertz - Best Deals, Offers & Price History`}</title>
                        <meta 
                            name="description" 
                            content={`Discover the latest prices, discounts, and deals for ${product.name} at GlamAlertz. Compare prices across multiple stores and find the best offers today! Get price history.`} 
                        />
                        <meta 
                            property="og:title" 
                            content={`${capitalizeFirstLetterOfEachWord(product.name)} | GlamAlertz - Best Deals & Price History`} 
                        />
                        <meta 
                            property="og:description" 
                            content={`Explore ${product.name}'s price history and compare offers from Amazon and other stores. Shop smart with GlamAlertz.`} 
                        />
                        <meta 
                            property="og:image" 
                            content={product.image || "/logo2.png"} 
                        />
                        <meta 
                            property="og:url" 
                            content={`https://www.glamalertz.com/product/${id}`} 
                        />
                        <meta 
                            name="twitter:card" 
                            content="summary_large_image" 
                        />
                        <meta 
                            name="twitter:title" 
                            content={`${capitalizeFirstLetterOfEachWord(product.name)} | GlamAlertz - Best Deals & Offers`} 
                        />
                        <meta 
                            name="twitter:description" 
                            content={`Discover the best deals on ${product.name}. Check out the latest prices, discounts, and offers today!`} 
                        />
                        <meta 
                            name="twitter:image" 
                            content={product.image || "/logo2.png"} 
                        />
                        <meta 
                            name="robots" 
                            content="index, follow" 
                        />
                        <meta 
                            name="keywords" 
                            content={`${product.name}, ${product.store}, price history, best deals, discounts, GlamAlertz`} 
                        />
                    </Helmet>

                    <Header />
                    <BackButton />
                    <div className="product-page">
                        <div className="product-header">
                            <div className="product-image">
                                <img 
                                    src={product.image || "/logo2.png"} 
                                    alt={`Image of ${product.name}`} 
                                    onError={(e) => e.target.src = '/logo2.png'} 
                                />
                                <img 
                                    className="store-logo"
                                    src={product.storeLogo} 
                                    alt={`Logo of ${product.store}`} 
                                    onError={(e) => e.target.src = '/logo2.png'} 
                                />
                            </div>
                            <div className="product-info">
                                <h1 className='product-name'>
                                    {`${capitalizeFirstLetterOfEachWord(product.name)} ${t('productPage.productName')}`}
                                </h1>
                                <div className="price-box">
                                    {product.prices.discounted ? (
                                        <>
                                            <span className="discounted-price">{`${t('priceBox.now')}  ${formatPrice(product.prices.discounted)} ${product.currency}`}</span>
                                        </>
                                    ) : (
                                        <span className="original-price-only">{`${t('priceBox.now')}  ${formatPrice(product.prices.original)} ${product.currency}`}</span>
                                    )}
                                </div>

                        <div className="product-actions">
                            <a 
                              href={getAffiliateLink(product.link, product.store)} className="get-this-item-now" target="_blank" rel="noopener noreferrer">{`${t('productPage.buyNow')} ${product.store}!`}
                            </a> 
                            <AddToRadar product={product} />
                        </div>


                                <div className="price-history-graph">
                                    <canvas ref={chartRef}></canvas>
                                </div>
                            </div>
                        </div>
                        {/* <div className="product-description">
                            <h3>Description</h3>
                            <p>{product.description}</p>
                        </div> */}
                        { product.productInfo != null ? 
                         <div className="product-info">
                            <h3>{t('productPage.productInfo')}</h3>
                            <p>{product.productInfo}</p>
                        </div> 
                        : null }

                        { similarProducts.length ? 
                        <div className="product-availability">
                            <h3>{t('productPage.similarItems')}</h3>
                            <Slider {...settings}>
                                {similarProducts.map((product) => (
                                   
                                    <div key={product._id} className="carousel-item">
                                        <ProductBox product={product} 
                                        // onClick={scrollToTop} 
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div> : null }
                    </div>
                </>
            ) : ( 
                <p>{t('general.loading')}</p>
            )}
            <Footer />
        </div>
    );
};

export default ProductPage;